.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  /* background-color: lightsteelblue; */
}

.circle-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.scale-panel {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  padding-left: 1em;
}

.scale-text {
  margin-top: 0;
  cursor: pointer;
  font-size: 5rem;
  color: #333;
}

.grade-text {
  font-size: 3rem;
  color: gray;
}
.scale-title {
  font-size: 5rem;
  color: #333;
  margin: 0;
  text-align: left;
}

.circle {
  cursor: pointer;
  position: relative;
  border: 2px solid #333;
  padding: 0;
  margin: 1em auto;
  width: 32em;
  height: 32em;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
}

.inner-circle {
  cursor: pointer;
  position: absolute;
  border: 2px solid #333;
  padding: 0;
  margin: 1em auto;
  width: 20em;
  height: 20em;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
}
li {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  border:  1px solid black;
}
/* li:hover {
  background-color: beige;
} */

.text {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  transform: skewY(60deg) rotate(15deg);
  padding-top: 20px;
  font-size: 1.5rem;
}
li:first-child {
  transform: rotate(0deg) skewY(-60deg);
}
li:nth-child(2) {
  transform: rotate(30deg) skewY(-60deg);
}
li:nth-child(3) {
  transform: rotate(60deg) skewY(-60deg);
}
li:nth-child(4) {
  transform: rotate(90deg) skewY(-60deg);
}
li:nth-child(5) {
  transform: rotate(120deg) skewY(-60deg);
}
li:nth-child(6) {
  transform: rotate(150deg) skewY(-60deg);
}
li:nth-child(7) {
  transform: rotate(180deg) skewY(-60deg);
}
li:nth-child(8) {
  transform: rotate(210deg) skewY(-60deg);
}
li:nth-child(9) {
  transform: rotate(240deg) skewY(-60deg);
}
li:nth-child(10) {
  transform: rotate(270deg) skewY(-60deg);
}
li:nth-child(11) {
  transform: rotate(300deg) skewY(-60deg);
}
li:nth-child(12) {
  transform: rotate(330deg) skewY(-60deg);
}/*
li:first-child .text {
  background: green;
}
li:nth-child(2) .text {
  background: tomato;
}
li:nth-child(3) .text {
  background: aqua;
}
li:nth-child(4) .text {
  background: yellow;
}
li:nth-child(5) .text {
  background: orange;
}
li:nth-child(6) .text {
  background: purple;
}
li:nth-child(7) .text {
  background: cyan;
}
li:nth-child(8) .text {
  background: brown;
}
li:nth-child(9) .text {
  background: gray;
}
li:nth-child(10) .text {
  background: pink;
}
li:nth-child(11) .text {
  background: maroon;
}
li:nth-child(12) .text {
  background: gold;
} */